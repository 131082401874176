<template>
  <div>
    <div class="loginbj flex items-center justify-around   w-screen h-screen"
         :style="{'background-image': `url(${noorgpath && orgpath.imageAllUrl ? orgpath.imageAllUrl : login01})`}">
      <div class="leftContent">
        <p class="p1">{{orgpath.orgName}}</p>
        <p class="p2 flex "><img :src="orgpath.orgLogoAllUrl" v-if="orgpath.orgLogoAllUrl && orgpath.orgLogoAllUrl != ''" class="leftContent_logo "> {{orgpath.slogan}}</p>

      </div>
      <div class="loginContent ">
        <div class="rightContent">
          <div class="title">用户登录</div>
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" style="width: 100%">
            <el-tab-pane label="密码登录" name="1"
                         v-if="noorgpath?(orgpath.templateLoginTypeList?(orgpath.templateLoginTypeList.length > 0?(orgpath.templateLoginTypeList.indexOf('账号密码登录') != -1?true:false):true):true):true"></el-tab-pane>
            <el-tab-pane label="手机号验证码" name="2"
                         v-if="noorgpath?(orgpath.templateLoginTypeList?(orgpath.templateLoginTypeList.length > 0?(orgpath.templateLoginTypeList.indexOf('手机号验证码登录') != -1?true:false):true):true):true"></el-tab-pane>
          </el-tabs>
          <OutlinedInput :value="form.name" placeholder="手机号 / 身份证号" :inputHeight="42"
                         :typeInput="'text'"
                         v-if="activeName==='1'"
                         @input="formInput($event,'name')"
          ></OutlinedInput>
          <OutlinedInput :value="form.pwd" placeholder="密码" :inputHeight="42"
                         :typeInput="'password'"
                         show-password
                         v-if="activeName==='1'"
                         @input="formInput($event,'pwd')"
          ></OutlinedInput>

          <OutlinedInput :value="form.phoneIdCard" placeholder="请输入手机号码" :inputHeight="42"
                         :typeInput="'text'"
                         v-if="activeName==='2'"
                         @input="formInput($event,'phoneIdCard')"
          ></OutlinedInput>

          <div class="flex items-center " v-if="activeName==='2'">
            <div class="" style="width:calc(100% - 130px )">
              <OutlinedInput :value="form.validateCode" placeholder="验证码" :inputHeight="42"
                             :typeInput="'text'"
                             @input="formInput($event,'validateCode')"
              ></OutlinedInput>
            </div>
            <div class="sendSms cursor-pointer" @click="getCodeFun">{{
                btnText
              }}
            </div>

          </div>
          <div class="p_t_20">
            <el-button type='primary' class='subm2' @click='onSubmit'>登录</el-button>
          </div>
          <div class='f'>
            <div style='width: 100%;' class='flex justify-between mt-5 '>
              <span style='color:#B3B3B3' class="cursor-pointer" @click='forget'
                    v-if="!noorgpath || orgpath.isPasswordBack == 1">忘记密码？</span>
              <span class='span_primary cursor-pointer' @click='register'
                    v-if="!noorgpath || orgpath.isUserRegister == 1">用户注册</span>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>


</template>

<script setup>
import {defineComponent, reactive, ref, onMounted} from 'vue'
import {Right} from '@element-plus/icons-vue'
import {ElMessage} from 'element-plus'
import {setLocal} from '../../utils/formExtend'
import {mapMutations, useStore} from 'vuex'
import router from '@/router/index'
import {getUserInfo, login, noticeCount, templateorgpath} from '@/api/index'
import '@/utils/registerMicroAppsConfig'
import {defineEmits} from 'vue'
import JSEncrypt from 'jsencrypt';
import OutlinedInput from "@/components/OutlinedInput.vue"
import {getPhoneCode, getRegisterSmsCode} from "@/api/UserInfo";
import {useRoute} from 'vue-router';
import login01 from './../../assets/img/User/login01.png'

const route = useRoute();
const emit = defineEmits(['isSuccess', 'register'])
const store = useStore();
const time = ref("")
const btnText = ref("获取验证码")
const btnDisabled = ref(false)
const orgpath = ref({
  slogan:'做一流绿色低碳综合能源服务商',
  templateName:'合邦能源网-应用平台',
  orgName:'合邦能源网-应用平台',
})
//获取手机验证码方法
const getCodeFun = () => {
  if (!btnDisabled.value) {
    // 校验手机号码
    if (!form.phoneIdCard) {
      //号码校验不通过
      ElMessage.error("手机号不能为空")
      //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
    } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.phoneIdCard)) {
      // 失去焦点后自动触发校验手机号规则
      ElMessage.error("手机号格式不正确")
    } else {
      let params = {
        "phone": form.phoneIdCard,
      }
      getPhoneCode(params).then(res => {
        if (res.code === 200) {
          ElMessage.success("发送成功")
          time.value = 60;
          btnDisabled.value = true;
          //调用倒计时方法
          timer();
        }
      }).catch(err => {
        btnText.value = "获取验证码";
        btnDisabled.value = false;
      })
    }
  }

}
const timer = () => {
  if (time.value > 0) {
    time.value--;
    btnText.value = time.value + "s后重新发送";
    setTimeout(timer, 1000);
  } else {
    time.value = 0;
    btnText.value = "获取验证码";
    btnDisabled.value = false;
  }
}

let form = reactive({
  password: '',
  phoneIdCard: '',
  validateCode: ''
})
const formInput = (row, type) => {
  console.log(row, type, form);
  if (row) {
    if (row.target) {
      form[type] = row.target.value
    }
  }
  // if(row.target.value){
  //   // form[type] = row.target.value
  // }
}
const activeName = ref('1')

const handleClick = (tab, event) => {
  console.log(tab, event)
}
const loginApi = (param, phone) => {
  login(param).then(res => {
    ElMessage.success('登录成功')
    let status = res.data.status
    const token = res.data.token
    setLocal('token', token)
    localStorage.setItem("tokenState", status)
    sessionStorage.setItem("tokenState", status)
    // 1 需要补充资料       2  跳转审批结果         3  ok 可以登录了
    if (status === 2) {
      // 告诉父元素登录成功了
      router.push('/reviewProgress')
    }
    if (status === 1) {
      // 告诉父元素登录成功了
      router.push('/registerPage?userId=userId&phone=' + phone)
    }
    if (status === 3) {
      getUsetInfoMsg()
    }

  }).catch(error => {
  })

}

// 获取用户信息
async function getUsetInfoMsg() {
  let token = sessionStorage.getItem("token")
  if (token) {
    await getUserInfo().then(res => {
      if (res.code === 200) {
        store.commit('SET_USERMANAGE', res.data.sysUser);
      }
    })
    // noticeCount
    // await noticeCount().then(res => {
    //   if (res.code === 200) {
    //     store.commit('SET_NOICENUMBER', res.data);
    //   }
    // })
    // 告诉父元素登录成功了
    router.replace('/app')
  }
}

const noorgpath = ref(null)
const gettemplateorgpath = () => {
  templateorgpath({relativePath: pathUrl.value}).then(res => {
    noorgpath.value = res.data ? true : false
    if(res.data){
      orgpath.value = res.data
    }
    document.title = orgpath.value ? orgpath.value.templateName : '合邦能源网-应用平台'
    activeName.value = orgpath.value ? (orgpath.value.templateLoginTypeList ? (orgpath.value.templateLoginTypeList.length > 0 ? (orgpath.value.templateLoginTypeList.indexOf('账号密码登录') != -1 ? '1' : '2') : '1') : '1') : '1'
  }).catch(error => {
  })

}
// 去goWebsite
const goWebsite = () => {
  window.open('https://pxap.sxhbdlkj.com/', '_blank')
}
const ruleForm = ref(null)
const enterSubmit = (e) => {
  if (e.key === 'Enter') {
    onSubmit()
  }
}
// 忘记密码
const forget = () => {
  router.push("/retrievePassword")
  // emit("retrievePassword")
}
// 注册
const register = () => {
  router.push("/registerPage")
}
const validate = async (formData) => {
  if (activeName.value === '2') {
    let p = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (!formData.phoneIdCard || formData.phoneIdCard.trim() === '') {
      ElMessage.error("手机号码不能为空")
      return false;
    }
    if (!p.test(formData.phoneIdCard)) {
      ElMessage.error("请输入正确的手机号格式")
      return false;
    }
    if (!formData.validateCode || formData.validateCode.trim() === '') {
      ElMessage.error("验证码不能为空")
      return false;
    }
  }
  if (activeName.value === '1') {
    if (!formData.name || formData.name.trim() === '') {
      ElMessage.error("手机号 / 身份证号不能为空")
      return false;
    }
    if (!formData.pwd || formData.pwd.trim() === '') {
      ElMessage.error("密码不能为空")
      return false;
    }
  }
  // 如果验证通过，返回 true
  return true;
};

const onSubmit = async () => {
  let {name, pwd, validateCode, phoneIdCard} = form
  if (!await validate(form)) return
  const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encName = encrypt.encrypt(name);
  const encPassword = encrypt.encrypt(pwd);
  let params = {}
  // 判断是那种登录方式
  if (activeName.value === "1") {
    // 账号密码
    params = {
      "password": encPassword,
      "phoneIdCard": encName,
    }
  }
  if (activeName.value === "2") {
    // 手机号验证码
    params = {
      "phoneIdCard": phoneIdCard,
      "validateCode": validateCode
    }
  }
  await loginApi(params, name || phoneIdCard)

}

const rules = reactive({
  name: [
    {
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('用户名不能为空'))
        }
        callback()
      }, trigger: 'blur'
    },
    {pattern: /^[a-zA-Z0-9_]+$/, message: '请输入由英文字母、下划线、数字构成的用户名', trigger: ['change', 'blur']}
  ],
  pwd: [
    {
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('密码不能为空'))
        }
        callback()
      }, trigger: 'blur'
    }
  ]
})
const pathUrl = ref(null)
onMounted(() => {
  pathUrl.value = route.query.path ? route.query.path : '/login'
  gettemplateorgpath()
  document.title = '合邦能源网-应用平台'
})
</script>
<style>

</style>
<style lang='less' scoped>
::v-deep(.demo-tabs) {
  .el-tabs__header, .el-tabs__nav-wrap, .el-tabs__nav {
    width: 100%;
  }

  .el-tabs__header {
    display: flex;
  }

  .el-tabs__item {
    flex: 1;
    max-width: 50%;
    width: 50%;
    text-align: center; /* 可选：使标签内容居中 */
  }
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.span_primary {
  color: #3381FF;
}

.loginbj {
  width: 100%;
  height: 100vh;
  // background-image: url('../../assets/img/User/login01.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .leftContent {
    max-width: 40vw;
    .p1 {
      color: #000;
      font-size: 50px;
      margin-bottom: 20px;
    }

    .p2 {
      color: #009be7;
      font-size: 40px;
    }
    .leftContent_logo{
      width: 48px;
      height: 48px;
      margin-right: 10px;
      margin-top: 6px;
    }
  }

  .loginContent {
    .rightContent {
      background: rgba(255, 255, 255, 0.91);
      padding: 10px 33px 35px 33px;
      text-align: center;
      width: 400px;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 2px rgba(64, 147, 250, 0.1);
      border-radius: 0 30px 0 30px;

      .title {
        text-align: left;
        position: relative;
        margin: 29px auto;
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }

      .sendSms {
        width: 117px;
        height: 42px;
        line-height: 42px;
        margin-left: 11px;
        background: #F2F2F2;
        border-radius: 1px 1px 1px 1px;
        border: 1px solid #DDDDDD;
      }

      .forit {
        height: 50px;
        box-sizing: border-box;
        margin: 0 auto 40px;
        border: 1px solid #DCDCDC;
        border-radius: 4px;

        .el-input {
          width: 100%;
          height: 100%;
        }

        ::v-deep(.el-input__inner) {
          width: 100%;
          height: 100%;
          border: none !important;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #333333 !important;
        }

        ::v-deep(.el-form-item__error) {
          top: 56px;
          left: 20px;
        }

        ::v-deep(.el-input__wrapper) {
          box-shadow: none;
          width: 100%;
          height: 100%;
          border: none;
        }

        .userinp {
          width: 100%;
          height: 90%;


          .prefixUserRight {
            width: 1px;
            height: 34px;
            background: #DCDCDC;
            margin-right: 16px;
          }


        }

        .code {
          width: 30%;
          text-align: right;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #3381FF;
          line-height: 79px;
          cursor: pointer;
          letter-spacing: 1px;
        }
      }

      //.forit02 {
      //  ::v-deep(.el-form-item__error) {
      //    top: 45px;
      //    left: 20px;
      //  }
      //}

      .subm2 {
        width: 100%;
        height: 44px;
        border-radius: 4px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 33px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        background: var(--el-color-primary);
      }

      .checkContent {
        width: 495px;
        margin: 70px auto 30px;
        text-align: left;
      }
    }
  }

  .bottomInfo {
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E7FBFF;
    line-height: 30px;
    opacity: 0.74;
    position: absolute;
    bottom: 44px;
    left: auto;
    right: auto;
  }

}

.fixedBottom {
  position: fixed;
  bottom: 10px;
  left: 50%;
}
</style>
