import './public-path';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-plus/dist/index.css'
import { registerMicroApps, start } from 'qiankun';
import '@/assets/css/index.css'
import rippleDirective from './utils/ripple';
import { registerSvgIcon } from '@/icons'
// import 'swiper/css'; // 轮播图底面的小圆点
import './assets/css/common.less'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import '@/assets/style/elementVariarbles.postcss'
// import SvgIcon from '@/components/svgIcon/SvgIcon.vue'// svg component
// 导入所有的 SVG 文件
// const requireAll = (requireContext) => {
//   const files = requireContext.keys().map(requireContext);
//   console.log('成功导入的 SVG 文件：', files);
//   return files;
// };
//
// const req = require.context('./icons/svg', true, /\.svg$/);
// const svgFiles = requireAll(req);
//
// // 验证导入是否成功
// if (svgFiles.length > 0) {
//   console.log('SVG 文件导入成功！');
// } else {
//   console.error('SVG 文件导入失败！');
// }

let routes = null;
let instance = null;
let history = null;
import ElementPlus from 'element-plus'
if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
    console.log = function () {
    }
    console.error = function () {
    }
    console.dir = function () {
    }
}


function render(props = {}) {
  const { container } = props;
  history = createWebHistory(window.__POWERED_BY_QIANKUN__ ? '/' : '/');
  instance = createApp(App);
  instance.use(ElementPlus,{locale})
  instance.use(router);
  instance.use(store);
  instance.directive('ripple', rippleDirective);
  // 注册 Element Plus 图标组件
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    instance.component(key, component)
  }
  registerSvgIcon(instance)
  instance.mount(container ? container.querySelector('#appf') : '#appf');
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log('%c ', 'color: green;', 'vue3.0 app bootstraped');
}

function storeTest(props) {
  props.onGlobalStateChange &&
    props.onGlobalStateChange(
      (value, prev) => console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev),
      true,
    );
  props.setGlobalState &&
    props.setGlobalState({
      ignore: props.name,
      user: {
        name: props.name,
      },
    });
}

export async function mount(props) {
  storeTest(props);
  render(props);
  instance.config.globalProperties.$onGlobalStateChange = props.onGlobalStateChange;
  instance.config.globalProperties.$setGlobalState = props.setGlobalState;
}

export async function unmount() {
  instance.unmount();
  instance._container.innerHTML = '';
  instance = null;
  routes = null;
  history.destroy();
}
