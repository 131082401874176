<template>
  <div id="appf">
    <qiankunMenu
        :appName="appName"
        :mode="mode"
        :imgUrl="logoPhoto"
        :senName="senName"
        :menuList="menuList"
        @navigatorTo="navigatorTo" @handleOptins="handleOptins"
        @goHome="goHome">
    </qiankunMenu>
  </div>
</template>
<script>
import {loadMicroApp, registerMicroApps, runAfterFirstMounted, start} from 'qiankun'
import routes from "@/router/index"
import actions from './action';
import {validate, setLocal} from './utils/formExtend'
import {
  getMenuPrv,
  getOrgInfoByUser,
  getAppInfo,
  getexchangeForToken,
  getUserInfo,
  getCurrentUserLoginTime, logout, addUserVisitRecord, noticeCount
} from "@/api";
import {mapMutations, mapState} from "vuex";
import store from "@/store";
import qiankunMenu from '@/views/User/qiankunMenu.vue';
import {handleThemeStyle} from "@/utils/common";
import {getActiveId} from "@/utils/tool";

export default {
  name: 'master',
  components: {
    qiankunMenu,
  },
  computed: {
    ...mapState([
      "headerImg",
      "menulistdic",
    ]),
  },
  data() {
    return {
      mode: "",// 导航方式
      pathname: "",//  当前路径
      IS_SHANJIAO: process.env.VUE_APP_SHANJIAO === 'true',
      IS_PG: process.env.VUE_APP_PG === 'true',
      loginTime: '',    // 获取首次登录时间
      loginSJ: false, // shanjiao
      loginCGD: false, // shanjiao
      loginHb: false, // hebang
      loginPG: false, // hebang
      isLoginState: true,
      isRegister: false, // 注册
      isAddInfoDom: false, // 补充资料
      isRetrievePass: false, // 忘记密码
      tenantList: [],
      appList: [],
      menuList: [],
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      logoPhoto: "", //logo
      appName: "", // app name
      senName: "", // app name
      enName: "", // app name
      img: '',
      imgUrl: '',
      defaultMenuId: "",
      isApp: false,
      defaultMenuGroup: [],
      announcementViewFlag: false
    }
  },

  created() {
    document.documentElement.style.setProperty(`--nav-bg-color`, "#fff");
    let otherTheme = JSON.parse(sessionStorage.getItem("otherTheme"))
    if (otherTheme) {
      this.mode = otherTheme.mode
    }
    //  下面的代码是很无效的代码，但是有用 ，适配的时候报错
    let obj = {
      "id": "7fa5c051985f4b59bc18d86f8aad0f23",
      "parentId": "0",
      "name": " ",
      "path": " ",
      "priority": 0,
      "valid": "0",
      "showFlag": "1",
      "parentPath": "",
      "level": 0
    }
    localStorage.setItem("menuList", JSON.stringify(obj))
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  },
  mounted() {
    /* 监听到卸载 报错后 重新刷新页面. 路由后退 强制回到主应用 导致子应用挂载报错问题修复*/
    window.addEventListener('error', (event)=> {
      if (event.message.includes("NOT_MOUNTED")) {
        window.location.reload();
      }
    });

    // // 监听路由变化
    window.addEventListener('popstate', this.handlePopstate)
    // 展示过程
    // 注册一个观察者函数
    actions.onGlobalStateChange((state, preState) => {
      if (state.noToken) {
        this.removeLocaStorage()
        this.isLoginState = sessionStorage.getItem('token') ? true : false
        let activePath = sessionStorage.getItem('pathLogin') ? sessionStorage.getItem('pathLogin') : '/'
        this.$router.push({path: activePath})
        this.logoPhoto = ''
      }
      // if (state.theme) {
      //   let theme = state.theme
      //   // 将主题样式对象转换为 CSS 自定义属性
      //   Object.keys(theme).forEach(key => {
      //     document.documentElement.style.setProperty(`--${key}`, theme[key]);
      //   });
      //   let primaryColor = theme['primary-color']
      //   if (primaryColor) {
      //     handleThemeStyle(primaryColor)
      //   }
      // }
      // if (state.otherTheme) {
      //   if (JSON.stringify(state.otherTheme) !== "{}") {
      //     this.mode = state.otherTheme.mode
      //   }
      // }


    })
    this.chooseTenant = sessionStorage.getItem('chooseTenant')
    this.chooseTenantName = sessionStorage.getItem('chooseTenantName') || ''
    this.logoPhoto = sessionStorage.getItem('logoPhoto') || null
    let token = sessionStorage.getItem('token')
    this.appName = sessionStorage.getItem('appName')
    this.appNameEnName = sessionStorage.getItem('appNameEnName')
    if (token) {
      this.isSuccess()
      // 获取主题信息  调用接口 ====== API
      let theme = sessionStorage.getItem('theme') ? JSON.parse(sessionStorage.getItem('theme')) : {}
      // 将主题样式对象转换为 CSS 自定义属性
      Object.keys(theme).forEach(key => {
        document.documentElement.style.setProperty(`--${key}`, theme[key]);
      });
      if (theme) {
        let primaryColor = theme['primary-color']
        if (primaryColor) {
          handleThemeStyle(primaryColor)
        }
      }
    }


  },
  methods: {
    ...mapMutations(['SET_USERMANAGE', "SET_NOICENUMBER"]),
    ...mapMutations(['setEditableTabs', 'SET_APPINFO']),
    handlePopstate(event) {
      // if (this.$route.path === '/login') {
      //   // 阻止默认行为
      //   event.preventDefault();
      // }
      if (event.singleSpaTrigger === 'pushState') {
        this.isApp = false
      }
      if (!event.singleSpaTrigger && (event.state.current === '/app' || event.state.forward === '/app' || event.state.title === '工作台' || this.isApp)) {
        this.isApp = true
        sessionStorage.removeItem('editableTabs');
        sessionStorage.removeItem('menuList');
        sessionStorage.removeItem('APPINFO');
        this.setEditableTabs([]);
        this.SET_APPINFO({});
        // 阻止默认行为  开启我的夺舍重生大法
        history.replaceState({
          title: '工作台'
        }, 'app', '/app'); // 修改为实际 URL
        document.title = '工作台'
      }

    },
    normalizePath(str) {
      // 分割路径
      const parts = str.split('/').filter(Boolean); // 过滤掉空字符串

      // 检查前三个部分是否相同
      const isThreeEqual = parts[0] === parts[1] && parts[1] === parts[2];
      if (isThreeEqual) {
        // 如果前三个部分相同，去掉最外层斜杠
        parts.shift();
      } else if (parts[0] !== parts[1]) {
        // 如果部分少于三个，补齐一层斜杠
        parts.unshift(parts[0]);
      }
      // 重新组合路径
      return `/${parts.join('/')}`;
    },
    // 获取路由
    resetData() {
      // 重置数据为初始值
      this.loginTime = '';
      this.pathname = '';
      this.loginSJ = false;
      this.loginHb = false;
      this.loginCGD = false;
      this.isLoginState = true;
      this.isRegister = false;
      this.isAddInfoDom = false
      this.isRetrievePass = false;
      this.tenantList = [];
      this.appList = [];
      this.menuList = [];
      this.chooseTenant = "";
      this.chooseTenantName = "";
      this.logoPhoto = "";
      this.appName = "";
      this.senName = "";
      this.enName = "";
      this.img = '';
      this.imgUrl = '';
      this.defaultMenuId = "";
      this.defaultMenuGroup = [];
      this.announcementViewFlag = false;
    },
    ...mapMutations(['SET_MENUID', 'SET_MENUGROUP', 'SET_HEADERIMG', 'SET_USERMANAGE', 'SET_ORGINFO', 'SET_APPINFO', 'SET_FUNLIST', 'setEditableTabs']),
    // 回到主页
    goHome() {
      sessionStorage.removeItem('appid')
      sessionStorage.removeItem('imgUrl')
      sessionStorage.removeItem('appName')
      sessionStorage.removeItem('appNameEnName')
      this.appName = ''
      this.appNameEnName = ''
      this.imgUrl = ''
      this.$router.push('/')
    },
    /*前往注册*/
    register() {
      this.isRegister = true
    },
    /*前往忘记密码*/
    retrievePassword() {

      this.isRetrievePass = true
    },

    /* 刷新资料 */
    // orgCodefun() {
    //   this.getOrgInfoByUserApi()
    // },
    async isSuccess(type = '') {
      // 记录 登录的地址信息
      if (type === 'login') {
        let pathLogin = location.pathname
        sessionStorage.setItem('pathLogin', pathLogin)
      }
      this.tenantList = []
    },

    // 关闭app
    closeApp() {
      this.appList.forEach(item => {
        item.show = false
      })
    },
    loginOut() {
      this.handleOptins("401")
    },
    handleOptins(text) {
      if (text === 'loginOut') {
        // 调用接口
        logout().then(res => {
          // 退出登录
          sessionStorage.removeItem('appid')
          // 退出登录去掉新增测算记录信息
          sessionStorage.removeItem('createCalculationForm');
          sessionStorage.removeItem('createCalculationTable');
          sessionStorage.removeItem('orgFullName');
          sessionStorage.removeItem('logoPhoto');
          sessionStorage.removeItem('userPath');
          sessionStorage.removeItem('menuList');
          sessionStorage.removeItem('name');
          sessionStorage.removeItem('imgUrl');
          this.resetData()
          let activePath = sessionStorage.getItem('pathLogin')
          actions.setGlobalState({noToken: true, activePath: activePath});
        })

      }
      if (text === '401') {
        //  退出登录 不调用接口
        sessionStorage.removeItem('appid')
        // 退出登录去掉新增测算记录信息
        sessionStorage.removeItem('createCalculationForm');
        sessionStorage.removeItem('createCalculationTable');
        sessionStorage.removeItem('orgFullName');
        sessionStorage.removeItem('logoPhoto');
        sessionStorage.removeItem('userPath');
        sessionStorage.removeItem('menuList');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('imgUrl');
        this.resetData()
        actions.setGlobalState({noToken: true, activePath: '/'});
      }
      if (text === 'userInfo') {
        // 记录跳转个人中心前页面的path
        let path = location.pathname + location.search
        sessionStorage.setItem('userPath', path)
        // 用户中心
        this.$router.push('/userInfo')
      }
    },
    // 清除缓存内容
    removeLocaStorage() {
      sessionStorage.removeItem('appid')
      sessionStorage.removeItem('createCalculationForm');
      sessionStorage.removeItem('createCalculationTable');
      sessionStorage.removeItem('orgFullName');
      sessionStorage.removeItem('logoPhoto');
      sessionStorage.removeItem('userPath');
      sessionStorage.removeItem('menuList');
      sessionStorage.removeItem('routerList');
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('imgUrl');
      sessionStorage.removeItem('editableTabs');
      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('appRow')
      sessionStorage.removeItem('appName')
      sessionStorage.removeItem('appNameEnName')
      sessionStorage.removeItem('chooseTenant')
      localStorage.removeItem('chooseTenantName')
      localStorage.removeItem('imgUrl');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('ORGINFO');
      localStorage.removeItem('topic');
      this.SET_ORGINFO({});
      this.SET_USERMANAGE({});
      this.SET_FUNLIST([]);
      this.SET_APPINFO({});
      this.setEditableTabs([]);
    },
    navigatorTo(row, id, groupId) {
      let url = row.path
      this.SET_MENUID(id);
      this.SET_MENUGROUP([groupId]);
      let token = sessionStorage.getItem('token')
      localStorage.setItem('menuList', JSON.stringify(row))
      sessionStorage.setItem('menuList', JSON.stringify(row))
      let to = row.path
      if (!token) {
        // 没有登录通知主应用
        actions.setGlobalState({noToken: true, activePath: to});
        return
      } else {
        /* 调用接口添加访问记录 */
        let params = {
          url: to,
          orgCode: localStorage.getItem("chooseTenant") || sessionStorage.getItem("chooseTenant") || ""
        }
        addUserVisitRecord(params).then(res => {
          console.log("访问记录添加成功", to)
        })
        if (to.indexOf('cgd') > -1) {
          this.$router.replace(to)
        } else {
          this.$router.push(to)
        }
        //  this.$router.push(to)
        // console.log(window.history)
        // // this.$nextTick(()=>{
        // //
        // // })
        // //

        /* 方法2  可以解决 但是不丝滑*/
        // history.pushState(null, url, url)
        // location.reload();
        /* 方法1  可以解决 但是不丝滑*/
        //  //window.top.location.href = url;  可以解决 但是不丝滑
      }
    },
  },

}
</script>

<style lang="postcss" scoped>

::v-deep(.el-empty__description p) {
  margin-top: 30px;
}


.f_14 {
  font-size: 14px;
}

.el-loading-spinner .el-loading-text {
  text-align: center;
}

.top_header {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.xiala {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url(./assets/img/xiala.png) center no-repeat;
}

.top_header .logo {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 650;
  font-style: normal;
  color: #333;
  font-size: 18px;
  margin: 0 20px;
}

.top_header .text {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 650;
  font-style: normal;
  color: #FFFFFF;
  font-size: 18px;
}


#appf {
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
}

.h-121 {
  height: 64px !important;
}

.w-64 {
  width: 256px !important;
}

.w-64 {
  width: 256px !important;
}

.c_fff {
  color: #fff;
  font-weight: bold;
}


.layoutSidebar_style {
  height: calc(100vh - 64px);

}

.classTestwyc {
  display: inline-block;
  padding: 10px 20px;
  width: 88px;
  height: 76px;
}

.classTestwyc .el-dropdown__list {
  height: 100%;
}

.classTestwyc .el-dropdown-menu {
  height: 100%;
  width: 100%;
}

.classTestwyc .el-dropdown-menu .el-dropdown-menu__item {
  /* height: 100%; */
  width: 100%;
  line-height: 22px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin: 0px;
  display: block;
  padding-bottom: 6px;
}

.loginTime {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666
}

</style>
